.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.photo {
  position: relative;
}

.photo:active {
  cursor: grabbing !important;
}

.photo .remove {
  position: absolute;
  top: 3px;
  right: 3px;
  display: none;
  line-height: 16px;
  width: 16px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  padding-bottom: 3px;
  cursor: pointer;
  user-select: none;
}

.photo:hover .remove {
  display: block;
}

.photo .remove:hover {
  background-color: #000;
  color: #fff;
}

div.dzu-dropzone { 
  overflow: auto;
  margin-left: 10px;
  margin-top: 10px;
  width: calc(100% - 20px);
}

label.dzu-inputLabelWithFiles {
  margin-top: 10px;
  margin-bottom: 10px;
}

label.dzu-inputLabel {
  color: #666;
  text-transform: uppercase;
  font-size: 16px;
}

div.dzu-previewContainer {
  padding: 0px 10px;
}

.pinnedButtons {
  position: fixed !important;
  bottom: 8px;
  right: 8px;
  z-index: 100;
}

.pinnedButtons button {
  background: white !important;
  box-shadow: 0px 0px 14px black;
  margin: 8px;
}